import React, { useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Spinner } from 'react-bootstrap';
import { Flip, toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import env from '@beam-australia/react-env';

import './globals.scss';
import AuthGuard from '../components/auth-guard/AuthGuard';
import { I18NextInit } from '../lib/i18next/I18next';
import { en } from '../lib/langs/en';
import { fr } from '../lib/langs/fr';
import Head from 'next/head';
import { MethodData, useAxios } from '../lib/custom-axios/CustomAxios';
import { initPath } from '../lib/api/Api';
import { ErrorBoundary } from '../lib/error/ErrorBoundary';
import { DefaultSeo, WebPageJsonLd } from 'next-seo';

interface IMyAppProps {
    Component: any;
    pageProps: any;
}

I18NextInit({en, fr});

const MyApp: React.FC<IMyAppProps> = ({Component, pageProps}) => {
    const router = useRouter();
    const [loading, setLoading] = useState<boolean>(false);
    const {data} = useAxios(initPath, {method: MethodData.GET});

    useEffect(() => {
        const handleStart = (url: string) => {
            url.split('?')[0] !== location.pathname ? setLoading(true) : setLoading(false);
        };
        const handleComplete = (url: string) => setLoading(false);

        router.events.on('routeChangeStart', handleStart);
        router.events.on('routeChangeComplete', handleComplete);
        router.events.on('routeChangeError', handleComplete);
    }, [router]);

    const combinedInitData = {
        ...(pageProps?.initData ?? {}),
        ...data,
    }

    // Make sure host is always string
    combinedInitData.host = combinedInitData.host || '';

    const canonicalUrl = combinedInitData.host + router.asPath.split('?')[0];

    return (
        <AuthGuard initData={combinedInitData}>
            <DefaultSeo
                title="Simplify Your Car Buying and Selling Experience | Purr"
                description="The simplest way to buy or sell a used car."
                canonical={canonicalUrl}
                openGraph={{
                    type: 'website',
                    url: combinedInitData.host + router.asPath,
                    images: [
                        {url: combinedInitData.host + '/assets/img/open-graph/default.png'},
                    ],
                }}
                twitter={{
                    cardType: 'summary_large_image',
                    site: '@Purr.ca',
                }}
                norobots={true}
            />
        
            <WebPageJsonLd
                id={combinedInitData.host}
                name="Purr"
                description="The simplest way to buy or sell a used car."
                url={combinedInitData.host}
                potentialAction={{
                    '@type': 'SearchAction',
                    target: `${combinedInitData.host}/marketplace?searchKeyword={search_term_string}`,
                    'query-input': 'required name=search_term_string',
                }}
                inLanguage="en-US"
            />

            <Head>
                {env('LOGGLY_KEY') && <script dangerouslySetInnerHTML={{
                    __html: `
                       var _LTracker = _LTracker || [];
                        _LTracker.push({
                          'logglyKey' : '4d1c0480-c64c-4fe2-934e-26022a5dd47c',
                          'sendConsoleErrors' : true,
                          'tag' : '${env('LOGGLY_KEY') || ''}'
                      });`
                }}/>}
            </Head>
            {loading ? (
                <div className={'page-loader'}>
                    <Spinner animation="border" variant="dark"/>
                </div>
            ) : null}
            <ErrorBoundary pathname={router.pathname}>
                <Component {...pageProps} />
            </ErrorBoundary>
            <ToastContainer position={toast.POSITION.BOTTOM_RIGHT}
                            style={{
                                width: '100%',
                                maxWidth: 650,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                            autoClose={3000}
                            transition={Flip}
                            hideProgressBar={true}
                            newestOnTop={true}
                            closeOnClick
                            rtl={false}/>
        </AuthGuard>
    );
};

export default MyApp;
