import React from 'react';

interface IInitialDataContext {
    host?: string;
    stripePublicKey?: string;
    googlePlacesApiKey?: string;
    r22Enabled?: boolean;
}

export const InitialDataContext: React.Context<IInitialDataContext> = React.createContext({});
