import { useContext, useEffect, useState } from 'react';
import { Button, Container, Dropdown, Nav, Navbar, Offcanvas } from 'react-bootstrap';

import styles from './header.module.scss';
import {
    activeNotificationIcon,
    mainLogoIcon,
    noNotificationIcon,
    notificationIcon,
    userRoundIcon
} from '../../lib/svg-icons/SvgIcons';
import { NavLink } from '../../lib/navlink/NavLink';
import { UserContext, UserCredentialType, UserType } from '../../contexts/user/UserContext';
import { useLoggedIn } from '../../lib/user-logged-in/UserLoggedIn';
import { useMediaQuery } from 'react-responsive';
import { useRouter } from 'next/router';

interface IDealerHeaderProps {
    defaultRoute: string;
}

const ConsumerHeader: React.FC<IDealerHeaderProps> = ({defaultRoute}) => {
    const location = useRouter();
    const {logout, isLoggedIn} = useLoggedIn();
    const {user, hasUnreadNotifications} = useContext(UserContext);
    const [showLoading, setShowLoading] = useState<boolean>(true);
    const [open, setOpen] = useState(false);
    const isMediumMobile = useMediaQuery({
        maxWidth: 992
    });
    const isMobile = isMediumMobile;
    const expandBP = 'lg';

    useEffect(() => {
        setOpen(false);
    }, [location.pathname]);

    useEffect(() => {
        !isMobile && setOpen(false);
    }, [isMediumMobile]);

    useEffect(() => {
        if (isLoggedIn) {
            user && setShowLoading(false);
        } else {
            setShowLoading(false);
        }
    }, [user]);
    const showDealerSignin = location.pathname.includes('/dealer') || location.pathname.includes('/verify-email');

    return (
        <div className={styles.header} id="consumer-header">
            <Navbar expand={expandBP} variant="dark" expanded={open}>
                <Container fluid="md" className="d-flex flex-row">
                    <Navbar.Brand as="span">
                        <Navbar.Toggle
                            className={'consumer-toggle'}
                            aria-controls="offcanvasHeaderNavbar"
                            onClick={() => {
                                setOpen(!open);
                            }}>
                            <svg width="16" height="24" viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16 3.9999H0.625V0.899902H16V3.9999Z" fill="white"/>
                                <path d="M16 13.9999H0.625V10.8999H16V13.9999Z" fill="white"/>
                                <path d="M16 23.9999H0.625V20.8999H16V23.9999Z" fill="white"/>
                            </svg>
                        </Navbar.Toggle>
                        <NavLink href={defaultRoute}>
                            {mainLogoIcon}
                        </NavLink>
                    </Navbar.Brand>
                    {
                        showLoading ? null : (
                            <>
                                {!isMobile ? (
                                    <>
                                        <Nav className="justify-content-start">
                                            <NavLink href="/buy">
                                                Buy
                                            </NavLink>
                                            <NavLink href="/sell" data-testid={'start-vehicle-lead'}>
                                                Sell
                                            </NavLink>
                                            <NavLink href="/free-appraisal">
                                                Free Appraisal
                                            </NavLink>
                                            <NavLink href="/about">
                                                About Purr
                                            </NavLink>
                                            <NavLink href="/blog" className="me-auto">
                                                Blog
                                            </NavLink>
                                            {!user ? (
                                                <>
                                                    {!showDealerSignin &&
                                                    <NavLink href="/signin" className={'p-0'}>
                                                      <span className="signinButton">Sign in</span>
                                                        {/* <Button className="signinButton"></Button> */}
                                                    </NavLink>}
                                                    {showDealerSignin &&
                                                    <NavLink href="/dealer/signin" className={'p-0 ms-md-3 ms-1'}>
                                                      <span className="signinButton">Dealer sign in</span>
                                                    </NavLink>}
                                                </>
                                            ) : user?.type === UserType.DEALER && (
                                                <NavLink href="/dealer/dashboard" className={'p-0'}>
                                                    <span className="signinButton">Dealer portal</span>
                                                </NavLink>
                                            )}
                                        </Nav>
                                    </>
                                ) : (
                                    <Nav className={'w-auto flex-row'}>
                                        {!user ? (
                                            <>
                                                {!showDealerSignin &&
                                                <NavLink href="/signin" className={'p-0'}>
                                                  <span className="signinButton">Sign in</span>
                                                    {/* <Button className="signinButton"></Button> */}
                                                </NavLink>}
                                                {showDealerSignin &&
                                                <NavLink href="/dealer/signin" className={'p-0 ms-sm-3 ms-1'}>
                                                  <span className="signinButton">Dealer sign in</span>
                                                </NavLink>}
                                            </>
                                        ) : user?.type === UserType.DEALER && (
                                            <NavLink href="/dealer/dashboard" className={'p-0'}>
                                                <span className="signinButton">Dealer portal</span>
                                            </NavLink>
                                        )}
                                    </Nav>
                                )}
                                {user && user?.type !== UserType.DEALER ? (
                                    <div className={`d-flex align-items-center order-${expandBP}-3`}>
                                        <Nav className="d-flex flex-row me-auto justify-content-end">
                                            <NavLink
                                                href="/notifications"
                                                className="me-3 p-0"
                                                renderChildren={(isActive) => {
                                                    return (
                                                        <div className="me-2">
                                                            {isActive ? activeNotificationIcon : hasUnreadNotifications ? notificationIcon : noNotificationIcon}
                                                        </div>
                                                    );
                                                }}
                                            />
                                            <Dropdown align={'end'} style={{gap: '5px'}} className="me-3 me-sm-0">
                                                <Dropdown.Toggle as={'div'} data-testid="user-icon">
                                                    {userRoundIcon}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.ItemText><strong>Hi {user?.firstName}!</strong></Dropdown.ItemText>
                                                    <Dropdown.Divider/>
                                                    <Dropdown.Item as={NavLink} href={'/profile?stage=account'}>
                                                        Account
                                                    </Dropdown.Item>
                                                    <Dropdown.Item as={NavLink} href={'/profile?stage=appointments'}>
                                                        Appointments
                                                    </Dropdown.Item>
                                                    <Dropdown.Item as={NavLink} href={'/profile?stage=my-vehicles'}>
                                                        Selling
                                                    </Dropdown.Item>
                                                    <Dropdown.Item as={NavLink} href={'/profile?stage=my-bids'}>
                                                        Buying
                                                    </Dropdown.Item>
                                                    <Dropdown.Item as={NavLink} href={'/profile?stage=saved'}>
                                                        Saved
                                                    </Dropdown.Item>
                                                    <Dropdown.Divider/>
                                                    <Dropdown.Item as={NavLink} href={'/profile?stage=profile'}>
                                                        Profile
                                                    </Dropdown.Item>
                                                    {user?.credentialType === UserCredentialType.INTERNAL && (
                                                        <Dropdown.Item as={NavLink} href={'/profile?stage=password'}>
                                                            Password
                                                        </Dropdown.Item>
                                                    )}
                                                    <Dropdown.Item as={NavLink} href={'/profile?stage=notifications'}>
                                                        Notification settings
                                                    </Dropdown.Item>
                                                    <Dropdown.Divider/>
                                                    <Dropdown.Item as={NavLink} href={'/profile?stage=documents'}>
                                                        Documents
                                                    </Dropdown.Item>
                                                    {/*<Dropdown.Item as={NavLink} href={'/profile?stage=pre-approvals'}>
                                                        Financing
                                                    </Dropdown.Item>*/}
                                                    <Dropdown.Divider/>
                                                    <Dropdown.Item as={NavLink} href={'/profile?stage=settings'}>
                                                        Settings
                                                    </Dropdown.Item>
                                                    <Dropdown.Divider/>
                                                    <Dropdown.Item as={NavLink} href={'/contact-form'}>
                                                        Contact Us
                                                    </Dropdown.Item>
                                                    <Dropdown.Item as={Button} onClick={signout}>
                                                        Sign Out
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Nav>
                                    </div>
                                ) : null
                                }
                                <Offcanvas
                                    id="offcanvasHeaderNavbar"
                                    aria-labelledby="offcanvasHeaderNavbar"
                                    placement="bottom"
                                    scroll={true}
                                    show={open}
                                    onHide={() => setOpen(!open)}
                                    className={`header-nav-offcanvas consumer-header order-${expandBP}-2`}>
                                    <Offcanvas.Header>
                                        <div>
                                            <svg width="78" height="24" viewBox="0 0 78 24" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M71.252 0H73.7008V0.896572H72.9387V3.23469H72.0158V0.896572H71.2537L71.252 0Z"
                                                    fill="white"/>
                                                <path
                                                    d="M76.4274 0H77.3521V3.23469H76.4274V1.65866L75.7066 2.86991H75.6231L74.9024 1.65866V3.23469H73.9785V0H74.9024L75.6644 1.31849L76.4274 0Z"
                                                    fill="white"/>
                                                <path
                                                    d="M11.6176 1.42065C12.761 1.39021 13.8963 1.62037 14.9375 2.09368C15.9787 2.567 16.8985 3.27107 17.6273 4.15256C19.2266 6.08068 20.0565 8.53198 19.9575 11.0351C20.0574 13.5386 19.2274 15.9904 17.6273 17.9185C16.8983 18.7996 15.9783 19.5033 14.9372 19.9765C13.896 20.4496 12.7609 20.6798 11.6176 20.6495C10.6931 20.6966 9.77041 20.5269 8.9231 20.1541C8.07579 19.7812 7.32735 19.2155 6.73748 18.5021V23.4473C6.73748 23.594 6.67923 23.7346 6.57554 23.8383C6.47185 23.942 6.33122 24.0002 6.18459 24.0002H0.552887C0.480207 24.0002 0.40824 23.9859 0.341103 23.958C0.273967 23.9302 0.212978 23.8894 0.161626 23.838C0.110274 23.7865 0.0695675 23.7255 0.0418341 23.6583C0.0141007 23.5911 -0.00011485 23.5191 6.98786e-07 23.4464V2.48247C6.98786e-07 2.33584 0.058251 2.19521 0.161937 2.09152C0.265624 1.98784 0.406252 1.92959 0.552887 1.92959H6.18547C6.25808 1.92959 6.32997 1.94389 6.39705 1.97167C6.46413 1.99946 6.52508 2.04018 6.57642 2.09152C6.62776 2.14286 6.66848 2.20381 6.69627 2.27089C6.72405 2.33797 6.73836 2.40987 6.73836 2.48247V3.56891C7.32814 2.85554 8.07642 2.28982 8.92354 1.91685C9.77067 1.54387 10.6932 1.37396 11.6176 1.42065ZM7.61207 13.4752C7.91272 13.7996 8.27981 14.0555 8.68832 14.2252C9.09682 14.395 9.53712 14.4746 9.9792 14.4587C10.4155 14.4761 10.8502 14.3969 11.2524 14.2269C11.6546 14.0569 12.0143 13.8002 12.3059 13.4752C12.8932 12.7979 13.2166 11.9315 13.2166 11.0351C13.2166 10.1386 12.8932 9.27223 12.3059 8.59499C12.0138 8.26937 11.6534 8.01235 11.2504 7.8423C10.8474 7.67226 10.4118 7.59341 9.9748 7.61139C9.53339 7.59627 9.09389 7.67637 8.68619 7.84624C8.27849 8.01612 7.91215 8.27178 7.61207 8.59586C7.02369 9.26799 6.71126 10.1378 6.73748 11.0307C6.70967 11.9253 7.02187 12.7971 7.61119 13.4708L7.61207 13.4752Z"
                                                    fill="white"/>
                                                <path
                                                    d="M33.3584 11.2901V2.48257C33.3584 2.40997 33.3727 2.33807 33.4005 2.27099C33.4283 2.20391 33.469 2.14296 33.5204 2.09162C33.5717 2.04028 33.6327 1.99956 33.6998 1.97177C33.7668 1.94399 33.8387 1.92969 33.9113 1.92969H39.543C39.6156 1.92969 39.6875 1.94399 39.7546 1.97177C39.8217 1.99956 39.8826 2.04028 39.934 2.09162C39.9853 2.14296 40.0261 2.20391 40.0538 2.27099C40.0816 2.33807 40.0959 2.40997 40.0959 2.48257V19.5878C40.0959 19.7344 40.0377 19.875 39.934 19.9787C39.8303 20.0824 39.6897 20.1406 39.543 20.1406H33.9113C33.7647 20.1406 33.6241 20.0824 33.5204 19.9787C33.4167 19.875 33.3584 19.7344 33.3584 19.5878V18.3572C32.7997 19.1149 32.0608 19.7215 31.2088 20.1221C30.3567 20.5226 29.4183 20.7045 28.4783 20.6513C27.566 20.6931 26.6564 20.5246 25.8197 20.1588C24.9829 19.793 24.2415 19.2398 23.6526 18.5418C22.4748 17.1354 21.8861 15.1081 21.8867 12.46V2.48257C21.8867 2.33594 21.945 2.19531 22.0487 2.09162C22.1523 1.98794 22.293 1.92969 22.4396 1.92969H28.0713C28.2179 1.92969 28.3586 1.98794 28.4623 2.09162C28.5659 2.19531 28.6242 2.33594 28.6242 2.48257V11.7278C28.6242 13.3539 29.3772 14.167 30.8832 14.167C31.2278 14.1885 31.5727 14.1308 31.8915 13.9982C32.2102 13.8657 32.4944 13.6618 32.7221 13.4023C33.177 12.7955 33.4024 12.0472 33.3584 11.2901Z"
                                                    fill="white"/>
                                                <path
                                                    d="M49.7293 2.48291V5.4627C50.0308 4.30461 50.7343 3.29146 51.714 2.60421C52.5589 2.01538 53.5471 1.66554 54.5743 1.59162C54.6516 1.58393 54.7296 1.59241 54.8035 1.61653C54.8773 1.64065 54.9454 1.67988 55.0033 1.73171C55.0611 1.78355 55.1076 1.84686 55.1397 1.91763C55.1717 1.98839 55.1887 2.06505 55.1896 2.14274V8.26756C55.1904 8.33823 55.177 8.40835 55.1502 8.47375C55.1234 8.53915 55.0838 8.5985 55.0336 8.64827C54.9834 8.69804 54.9238 8.73722 54.8581 8.76347C54.7925 8.78973 54.7223 8.80253 54.6516 8.80111C53.4821 8.71847 52.3203 9.04506 51.3651 9.72493C50.8088 10.1461 50.3672 10.7003 50.0809 11.3366C49.7946 11.9729 49.6728 12.6709 49.7266 13.3666V19.5872C49.7266 19.7339 49.6684 19.8745 49.5647 19.9782C49.461 20.0819 49.3204 20.1401 49.1737 20.1401H43.5412C43.3945 20.1401 43.2539 20.0819 43.1502 19.9782C43.0465 19.8745 42.9883 19.7339 42.9883 19.5872V2.48291C42.9883 2.33628 43.0465 2.19565 43.1502 2.09196C43.2539 1.98828 43.3945 1.93003 43.5412 1.93003H49.1737C49.3204 1.93003 49.461 1.98828 49.5647 2.09196C49.6684 2.19565 49.7266 2.33628 49.7266 2.48291H49.7293Z"
                                                    fill="white"/>
                                                <path
                                                    d="M63.859 2.48174V5.46153C64.1603 4.30279 64.8638 3.28896 65.8438 2.60129C66.6887 2.01245 67.6769 1.66261 68.7041 1.58869C68.7814 1.581 68.8595 1.58948 68.9333 1.6136C69.0072 1.63772 69.0752 1.67695 69.133 1.72878C69.1909 1.78062 69.2374 1.84393 69.2694 1.9147C69.3015 1.98546 69.3185 2.06213 69.3194 2.13981V8.26463C69.3202 8.3353 69.3068 8.40542 69.28 8.47082C69.2533 8.53622 69.2136 8.59557 69.1634 8.64534C69.1132 8.69511 69.0535 8.73429 68.9879 8.76054C68.9223 8.7868 68.8521 8.7996 68.7814 8.79818C67.6119 8.71554 66.45 9.04213 65.4949 9.722C64.9384 10.1431 64.4967 10.6972 64.2102 11.3335C63.9238 11.9698 63.8018 12.6679 63.8555 13.3637V19.5843C63.8555 19.7309 63.7973 19.8716 63.6936 19.9752C63.5899 20.0789 63.4493 20.1372 63.3026 20.1372H57.6701C57.5234 20.1372 57.3828 20.0789 57.2791 19.9752C57.1754 19.8716 57.1172 19.7309 57.1172 19.5843V2.48174C57.1172 2.33511 57.1754 2.19448 57.2791 2.09079C57.3828 1.98711 57.5234 1.92886 57.6701 1.92886H63.3026C63.4493 1.92886 63.5899 1.98711 63.6936 2.09079C63.7973 2.19448 63.8555 2.33511 63.8555 2.48174H63.859Z"
                                                    fill="white"/>
                                            </svg>
                                        </div>
                                        <Button
                                            variant="link"
                                            className={'link-button mobile-close'}
                                            onClick={() => {
                                                setOpen(!open);
                                            }}>
                                            <span className="ms-2">
                                                <svg width="15" height="18" viewBox="0 0 15 18" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M9.57495 9L14.95 18H11.025L7.62495 12.3L4.22495 18H0.324951L5.67495 9.025L0.599951 0.5H4.49995L7.62495 5.75L10.75 0.5H14.65L9.57495 9Z"
                                                        fill="white"/>
                                                </svg>
                                            </span>
                                        </Button>
                                    </Offcanvas.Header>
                                    <Offcanvas.Body>
                                        <Nav>
                                            <NavLink href="/buy">
                                                Buy
                                            </NavLink>
                                            <NavLink href="/sell">
                                                Sell
                                            </NavLink>
                                            <NavLink href="/free-appraisal">
                                                Free Appraisal
                                            </NavLink>
                                            <NavLink href="/about">
                                                About Purr
                                            </NavLink>
                                            <NavLink href="/blog" className='mb-5'>
                                                Blog
                                            </NavLink>
                                            {!user ? (
                                                <>
                                                    {!showDealerSignin &&
                                                    <NavLink href="/signin" className={'small'}>
                                                      Sign in
                                                    </NavLink>}
                                                    {showDealerSignin &&
                                                    <NavLink href="/dealer/signin" className={'small'}>
                                                      Dealer sign in
                                                    </NavLink>}
                                                </>
                                            ) : user?.type === UserType.DEALER && (
                                                <NavLink href="/dealer/dashboard" className={'small'}>
                                                    Dealer portal
                                                </NavLink>
                                            )}
                                            <NavLink href="/dealer" exact className="me-auto small">
                                                Dealers
                                            </NavLink>
                                            <div className="d-flex align-items-center gap-2 mt-5">
                                                <a href="https://www.instagram.com/purr.ca" target="_blank">
                                                <img width="33" src="/marketing-assets/images/social/PURR_SM_Icons-Instagram.svg" />
                                                </a>
                                                <a href="https://www.facebook.com/Purr-103458345622743" target="_blank">
                                                <img width="33" src="/marketing-assets/images/social/PURR_SM_Icons-Facebook.svg" />
                                                </a>
                                                <a href="https://www.linkedin.com/company/purr-ca/about/?viewAsMember=true" target="_blank">
                                                <img width="33" src="/marketing-assets/images/social/PURR_SM_Icons-Linkedin.svg" /></a>
                                            </div>
                                            <NavLink href="/terms" className="me-auto mt-5 small">
                                                Terms & Conditions
                                            </NavLink>
                                            <NavLink href="/privacy" className="me-auto small">
                                                Privacy Policy
                                            </NavLink>
                                            <p className="mt-4 white">© 2022 PURR ™ All rights reserved.</p>
                                        </Nav>
                                    </Offcanvas.Body>
                                </Offcanvas>
                            </>
                        )
                    }
                </Container>
            </Navbar>
        </div>
    );

    function signout() {
        logout();
        window.location.href = '/signin';
    }
};

export default ConsumerHeader;
